import { MenuModifierItem } from '../../features/Menu/types';

const mapModItems = (modItems: MenuModifierItem[]): MenuModifierItem[] => {
  return modItems.map((modItem) => {
    const icons = modItem.allergens.concat(modItem.attributes);
    return {
      ...modItem,
      allergens: icons
    };
  });
};

export default mapModItems;
