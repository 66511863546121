import { AxiosResponse } from 'axios';
import { MenuItem } from '../../features/Menu/types';
import mapAllModGroups from './mapAllModGroups';
import { MenuOptions } from '../../features/Cart/types';
import { StoreDay } from '../../types/stores';

const processMenuResponse = (menuResponse: AxiosResponse) => {
  const menuId = menuResponse.data.menuId;
  const menuResponseItemGroups = menuResponse.data.menu;
  const menuType: MenuOptions = menuResponse.data.pricingOptions;
  const menuHours: StoreDay[] = menuResponse.data.menuHours;
  const closingTime: string = menuResponse.data.endTime;
  const menuCategories: string[] = [];
  const menuItems: MenuItem[] = [];

  menuResponseItemGroups.forEach(
    (menuResponseItemGroup: { name: string; items: MenuItem[] }) => {
      if (menuResponseItemGroup.items.length <= 0) {
        return;
      }

      menuCategories.push(menuResponseItemGroup.name);

      menuResponseItemGroup.items.forEach((menuResponseItem: MenuItem) => {
        const processedMenuResponseItem: MenuItem = {
          productId: menuResponseItem.productId,
          category: menuResponseItemGroup.name,
          dineInPrice: Number(menuResponseItem.dineInPrice),
          takeOutPrice: Number(menuResponseItem.takeOutPrice),
          name: menuResponseItem.name,
          description: menuResponseItem.description,
          allergens: menuResponseItem.allergens,
          dietaryOptions: menuResponseItem.dietaryOptions.sort((a, b) =>
            a > b ? 1 : -1
          ),
          imageUrl: menuResponseItem.imageUrl,
          calories: Number(menuResponseItem.calories),
          attributes: menuResponseItem.attributes,
          modGroups: mapAllModGroups(menuResponseItem.modGroups),
          isSuspended: menuResponseItem.isSuspended
        };

        menuItems.push(processedMenuResponseItem);
      });
    }
  );

  return {
    menuCategories,
    menuItems,
    menuId,
    menuType,
    menuHours,
    closingTime
  };
};

export default processMenuResponse;
