import { logEvent as firebase } from 'firebase/analytics';
import { useFirebase } from './useFirebase';
import { useSiteConfig } from './useSiteConfig';
import { appConfig } from '../config/appConfig';
import { getSession, SessionKeys } from '../utils/Session/Session';
import { useStore } from './useStore';
import { useParams } from 'react-router-dom';
import logger, { LoggerEvent } from '../services/logger';

export interface AnalyticPayload {
  [index: string]: string | number | boolean | null;
}

export const useAnalytics = () => {
  const { analytics } = useFirebase();
  const { partnerConfig: siteConfig } = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(siteConfig.partnerId, shortId ?? '');
  let storeId: string | null = null;
  let storeName: string | null = null;

  if (store) {
    storeId = store.shortId;
    storeName = store.name;
  }

  const orderType = sessionStorage.getItem(SessionKeys.ORDER_TYPE);

  const logEvent = (event: string, payload: AnalyticPayload = {}) => {
    const analyticPayload: AnalyticPayload = {
      platform: 'WEB',
      ...payload
    };

    if (storeId) {
      analyticPayload.store_id = storeId;
    }

    if (storeName) {
      analyticPayload.store_name = storeName;
    }

    if (siteConfig.shortCode) {
      analyticPayload.short_code = siteConfig.shortCode;
    }

    if (siteConfig.partnerName) {
      analyticPayload.partner_name = siteConfig.partnerName;
    }

    if (siteConfig.partnerId) {
      analyticPayload.partner_id = siteConfig.partnerId;
    }

    if (orderType) {
      analyticPayload.orderType = orderType;
    }

    analyticPayload.environment = appConfig.environment ?? 'DEVELOPMENT';
    analyticPayload.x_requestId = getSession().id;

    firebase(analytics, event, analyticPayload);
    logger.info(LoggerEvent.ANALYTICS, event, { payload: analyticPayload });
  };

  return logEvent;
};
