import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Snackbar, Alert } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { OrderFee } from '../../../../types/fees';

const StyledInfoIcon = styled(InfoOutlinedIcon)(({ theme }) => ({
  fill: theme.colors.base.white,
  height: theme.spacing(5),
  width: theme.spacing(5),
  alignSelf: 'center'
}));

const StyledFeesContainer = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(4),
  '& > *': {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  '& > ul': {
    paddingLeft: theme.spacing(6.5)
  }
}));

const ServiceFeeAlert = ({
  serviceFee,
  additionalFees
}: {
  serviceFee: number;
  additionalFees: OrderFee[];
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      sx={{ width: '100%' }}
    >
      <Alert
        icon={<StyledInfoIcon />}
        color={undefined}
        sx={{
          backgroundColor: theme.colors.base.black,
          color: theme.colors.base.white,
          width: `calc(100% - ${theme.spacing(8)})`,
          fontSize: theme.spacing(4)
        }}
      >
        <StyledFeesContainer>
          <span>{t('restaurant.menu.orderContainsFees')}</span>
          <ul>
            <li>
              {t('restaurant.menu.serviceFeeAlert').replace(
                '(FEE)',
                `${serviceFee}%`
              )}
            </li>
            {additionalFees.map((fee) => (
              <li>
                {fee.fee}% {fee.name}: {fee.description}
              </li>
            ))}
          </ul>
        </StyledFeesContainer>
      </Alert>
    </Snackbar>
  );
};

export default ServiceFeeAlert;
