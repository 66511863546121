import { useState, ChangeEvent, KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Chip, { ChipProps } from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import InformationDrawer from '../../../../components/InformationDrawer/InformationDrawer';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { fetchOrderCalculations } from '../../../../services/orderService';
import { StoreInterface } from '../../../../types/stores';
import { useCartV2 } from '../../../../hooks/useCartV2';
import getCalculateObject from '../../../../utils/API/getCalculateObject';
import CircularProgress from '@mui/material/CircularProgress';
import { PricingOptions } from '../../types';
import { OrderType } from '../../../../types/order';

interface PromoInputProps {
  store?: StoreInterface;
  menuId: number;
}

const DiscountInputContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  '& label.Mui-focused': {
    color: theme.colors.base.black
  },
  '& .MuiOutlinedInput-root': {
    textTransform: 'uppercase',
    '&.Mui-focused fieldset': {
      borderColor: theme.colors.gray[300]
    }
  }
}));

const DiscountTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-input': {
    textTransform: 'capitalize'
  },
  ' & input::placeholder': {
    color: theme.colors.base.black,
    opacity: 1
  },
  '& .MuiFormHelperText-root': {
    fontSize: theme.spacing(3.5)
  }
}));

const StyledApplyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.colors.partner.button.primary.background,
  boxShadow: 'none',
  color: theme.colors.partner.button.primary.font,
  borderRadius: theme.spacing(2),
  fontSize: theme.spacing(4),
  fontWeight: 400,
  width: theme.spacing(20),
  height: theme.spacing(10),
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(4),
  '&:hover': {
    backgroundColor: theme.colors.partner.button.primary.background
  }
}));

const StyledClearIcon = styled(CancelIcon)(({ theme }) => ({
  height: theme.spacing(5),
  width: theme.spacing(5),
  position: 'absolute',
  top: theme.spacing(4),
  right: theme.spacing(4)
}));

const DiscountSuccessChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: 'transparent',
  borderColor: theme.colors.success[500],
  marginTop: theme.spacing(3),
  '.MuiChip-deleteIcon': {
    color: theme.colors.base.black
  }
}));

const ConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.partner[500]
}));

const StyledDiscountAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: 'none',
  borderBottom: '1px solid',
  borderColor: theme.colors.gray[300],
  '.MuiAccordionSummary-root': {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4)
  },
  '&.MuiPaper-root:before': {
    backgroundColor: 'transparent'
  },
  '.MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0)
  },
  '.MuiAccordionDetails-root': {
    marginBottom: theme.spacing(8),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(0)
  },
  '.MuiPaper-elevation': {
    margin: theme.spacing(0)
  },
  '.MuiSvgIcon-root': {
    color: theme.colors.base.black
  }
}));

const StyledInfoIconButton = styled(IconButton)(({ theme }) => ({
  '.MuiIconButton-root': {
    height: theme.spacing(5),
    width: theme.spacing(5),
    marginLeft: theme.spacing(2)
  }
}));

const PromoInput = ({ store, menuId }: PromoInputProps) => {
  const { t } = useTranslation();
  const [showApply, setShowApply] = useState(false);
  const [showClear, setShowClear] = useState(false);
  const [promoInputValue, setPromoInputValue] = useState('');
  const [error, setError] = useState(false);
  const [alcoholPromoError, setAlcoholPromoError] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const {
    promoCode,
    setPromoCode,
    items: cartItems,
    priceToDisplay
  } = useCartV2();
  const logEvent = useAnalytics();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [showPromoInfo, setShowPromoInfo] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const analyticPayload = {
    code: promoInputValue
  };

  const cartCalculationObject = getCalculateObject(
    cartItems,
    priceToDisplay === PricingOptions.TAKEOUT
      ? OrderType.takeOut
      : OrderType.dineIn,
    promoInputValue,
    menuId
  );

  const handlePromoSuccess = () => {
    setError(false);
    setShowApply(false);
    setShowClear(false);
    setPromoInputValue('');
    setPromoCode(promoInputValue);
    logEvent('discount_code_entered', {
      ...analyticPayload,
      success: 1
    });
  };

  const handlePromoError = () => {
    setError(true);
    setShowApply(false);
    setShowClear(true);
    logEvent('discount_code_entered', {
      ...analyticPayload,
      success: 0
    });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>): void => {
    if ((e.target as HTMLInputElement).value.length === 0) {
      setShowApply(false);
      return;
    }

    setShowApply(true);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setPromoInputValue(e.target.value);
    if (e.target.value === '') {
      setError(false);
      setAlcoholPromoError(false);
    }
  };
  const handleFocus = (): void => {
    if (!promoCode) {
      setShowClear(false);
    }
  };

  const isOnlyAlcohol = (): boolean => {
    const alcoholCheck = cartItems.filter((item) => {
      if (
        item.attributes.find(
          (attribute) => attribute.name.toLowerCase() === 'alcohol'
        )
      ) {
        return item;
      }
    });
    return alcoholCheck.length === cartItems.length;
  };

  const setPromo = () => {
    setShowLoading(true);
    if (isOnlyAlcohol()) {
      setAlcoholPromoError(true);
      setShowApply(false);
      setShowClear(true);
      return;
    }

    fetchOrderCalculations(store ? store.id : '', cartCalculationObject)
      .then((response) => {
        if (
          response?.data.summary.discount &&
          response?.data.summary.discount > 0
        ) {
          handlePromoSuccess();
          setShowLoading(false);
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.message.toLowerCase().includes('discount') // Still discount code error on the backend
        ) {
          handlePromoError();
          setShowLoading(false);
        }
      });
  };

  const setErrorText = (): string => {
    if (error) {
      return t('restaurant.cart.mdcError');
    }
    if (alcoholPromoError) {
      return t('restaurant.cart.alcoholPromoDisclaim');
    }
    return '';
  };

  const handleBlur = (): void => {
    if (!promoInputValue) {
      setShowApply(false);
    }
  };

  const handleClear = (): void => {
    setPromoInputValue('');
    setError(false);
    setAlcoholPromoError(false);
    setShowClear(false);
  };

  const handleDelete = (): void => {
    setOpenConfirmationDialog(true);
  };

  const handleClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleConfirmDelete = () => {
    setPromoCode('');
    logEvent('discount_code_removed', { ...analyticPayload });
    setOpenConfirmationDialog(false);
  };

  const handleTabChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleInfo = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    setShowPromoInfo(true);
  };

  return (
    <>
      <StyledDiscountAccordion
        aria-label="discount accordion"
        role="region"
        expanded={expanded === 'discount-panel'}
        onChange={handleTabChange('discount-panel')}
        disableGutters
        square
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="discount-panelbh-content"
          id="discount-panelbh-header"
        >
          <Typography data-testid="discount-code-text">
            {t('restaurant.cart.mdcCode')}
          </Typography>
          <StyledInfoIconButton aria-label="discount info" onClick={handleInfo}>
            <InfoOutlinedIcon />
          </StyledInfoIconButton>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <DiscountInputContainer data-testid="discount-container">
              <DiscountTextField
                id="member-discount-input"
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyUp={handleKeyUp}
                error={error || alcoholPromoError}
                value={promoInputValue}
                helperText={setErrorText()}
                data-testid="discount-input"
                placeholder={t('restaurant.cart.enterCode')}
                disabled={promoCode ? true : false}
              />
              {showApply && (
                <StyledApplyButton
                  disableRipple
                  variant="contained"
                  onClick={setPromo}
                  value={promoInputValue}
                  data-testid="apply-discount-button"
                  disabled={showLoading}
                >
                  {showLoading && <CircularProgress size={'1.8rem'} />}
                  {!showLoading && t('restaurant.cart.apply')}
                </StyledApplyButton>
              )}
              {showClear && (
                <StyledClearIcon
                  role="button"
                  aria-label="clear discount field"
                  onClick={handleClear}
                  data-testid="clear-discount-button"
                ></StyledClearIcon>
              )}
            </DiscountInputContainer>
            {/* Discount success */}
            {promoCode && (
              <DiscountSuccessChip
                label={promoCode.toUpperCase()}
                onDelete={handleDelete}
                variant="outlined"
                data-testid="discount-success-chip"
                aria-label={`${promoCode.toUpperCase()} Remove this discount using the backspace or delete key`}
              />
            )}
            {/* Discount code removal dialog */}
            <ConfirmationDialog
              open={openConfirmationDialog}
              handleClose={handleClose}
              title={t('restaurant.cart.removeCode')}
              content={t('restaurant.cart.removeDiscountCode')}
              data-testid="confirmation-dialog"
            >
              <ConfirmationButton
                onClick={handleClose}
                data-testid="dialog-cancel"
              >
                {t('restaurant.confirm.cancel')}
              </ConfirmationButton>
              <ConfirmationButton
                onClick={handleConfirmDelete}
                data-testid="dialog-remove"
              >
                {t('restaurant.confirm.remove')}
              </ConfirmationButton>
            </ConfirmationDialog>
          </>
        </AccordionDetails>
      </StyledDiscountAccordion>
      <InformationDrawer
        show={showPromoInfo}
        onClose={() => setShowPromoInfo(false)}
        disclaim={t('restaurant.cart.alcoholPromoDisclaim')}
        title={t('restaurant.cart.mdcCode')}
        data-testid="discount-input-drawer"
      />
    </>
  );
};

export default PromoInput;
