import { useParams, useLocation } from 'react-router-dom';
import MenuHeader from './components/MenuHeader';
import CartTrackingDrawer from './components/CartTrackingDrawer';
import ViewCartBanner from './components/ViewCartBanner/ViewCartBanner';
import { CartProviderV2 } from '../../contexts/Cart/CartContextV2';
import { useSiteConfig } from '../../hooks/useSiteConfig';
import { useStore } from '../../hooks/useStore';
import PickupTimeAlert from '../../components/PickupTimeAlert/PickupTimeAlert';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material';
import AppLayout from '../../layouts/AppLayout';
import { useColorConfig } from '../../hooks/useColorConfig';
import OrderingUnavailableDialog from '../../components/OrderingUnavailableDialog/OrderingUnavailableDialog';
import MenuDetail from '../Menu/components/MenuDetail/MenuDetail';
import Recaptcha from '../../components/Recaptcha/Recaptcha';
import { PaymentGatewayType } from '../../types/fetch';
import { appConfig } from '../../config/appConfig';

const Menu = () => {
  const siteConfig = useSiteConfig();
  const { storeId: shortId } = useParams();
  const { data: store } = useStore(
    siteConfig.partnerConfig.partnerId,
    shortId ?? ''
  );
  const { state } = useLocation();
  const theme = useColorConfig();
  const [openOrderingUnavailableDialog, setOpenOrderingUnavailableDialog] =
    useState<boolean>(store?.isMobileActive === false ?? false);
  const paymentGateway = siteConfig.paymentGateway?.paymentGateway;
  const showRecaptcha =
    appConfig.recaptchaEnabled &&
    paymentGateway === PaymentGatewayType.FreedomPay;

  if (!siteConfig || !store || !shortId) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <AppLayout>
        <CartProviderV2 shortId={shortId}>
          <Helmet>
            <title>
              {state?.title ? `${state?.title} Menu Page` : 'Menu Page'}
            </title>
          </Helmet>
          <ViewCartBanner store={store} />
          <OrderingUnavailableDialog
            open={openOrderingUnavailableDialog}
            close={() =>
              setOpenOrderingUnavailableDialog(!openOrderingUnavailableDialog)
            }
          />
          <CartTrackingDrawer store={store} />
          <MenuHeader store={store} />
          <MenuDetail store={store} />
          <PickupTimeAlert />
          {showRecaptcha && <Recaptcha location={'MENU'} />}
        </CartProviderV2>
      </AppLayout>
    </ThemeProvider>
  );
};

export default Menu;
