import { FC, useLayoutEffect, useRef, useState } from 'react';
import AllergenIcon from '../../../components/AllergenIcon';
import MenuItemDescription from '../../Menu/components/MenuItemDescription';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MenuItem } from '../../Menu/types';
import FormatPrice from '../../Menu/components/FormatPrice/FormatPrice';
import { useTranslation } from 'react-i18next';
import { PricingOptions } from '../../Cart/types';
import BadgeUnavailable from '../../../components/Badges/BadgeUnavailable';
import mapAllergenStringToSVG from '../../../utils/API/mapAllergenStringToSVG';
import Button from '@mui/material/Button/Button';

interface MenuItemDetailsProps {
  item: MenuItem;
  priceToDisplay?: PricingOptions;
}

const ContentContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
  marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  color: theme.colors.base.black
}));

const MenuItemPrice = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(5),
  fontWeight: 700,
  lineHeight: 1
}));

const SubtitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center'
});

const MenuItemCalories = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3.5),
  weight: 400,
  marginTop: '1px'
}));

const InfoDivider = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(5),
  weight: 400,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const AllergenIconsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  paddingBottom: theme.spacing(1),
  flexWrap: 'wrap'
}));

const StyledAllergenPill = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  background: theme.colors.gray[25],
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(1)
}));

const StyledItemPriceAndCalloriesContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2)
}));

const StyledToggleButton = styled(Button)(({ theme }) => ({
  display: 'inline-flex',
  color: theme.colors.base.black,
  fontSize: theme.spacing(3.5),
  padding: `${theme.spacing(1)} 0`,
  marginBottom: theme.spacing(2),
  textDecoration: 'underline',
  textTransform: 'unset',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'transparent'
  }
}));

const MenuItemDetails: FC<MenuItemDetailsProps> = (props) => {
  const { t } = useTranslation();

  const showMoreRef = useRef<HTMLButtonElement | null>(null);
  const allergensContainerRef = useRef<HTMLDivElement | null>(null);

  const [numberOfIconsDisplay, setNumberOfIconsDisplay] = useState(-1);

  const priceDisplay =
    props.priceToDisplay === PricingOptions.TAKEOUT
      ? props.item.takeOutPrice
      : props.item.dineInPrice;

  const [showMore, setShowMore] = useState(false);
  const onToggleShowMore = () => setShowMore(!showMore);

  const combinedIcons = props.item.allergens.concat(props.item.dietaryOptions);

  const elementWillOverflow = (
    container: number,
    element: HTMLElement | undefined,
    allergens: number
  ) => {
    if (!element) {
      return false;
    }

    return container < allergens + element.scrollWidth;
  };

  const itemOnNextRow = (element: HTMLElement, currentRow: number) => {
    // the browser can push the element down rendering it in the second+ row

    const elementTop =
      element.offsetTop - allergensContainerRef.current!.offsetTop;

    return elementTop - 1 > elementTop * (currentRow - 1);
  };

  useLayoutEffect(() => {
    if (numberOfIconsDisplay > -1) {
      return;
    }

    if (!allergensContainerRef.current || !showMoreRef.current) {
      return;
    }

    const containerWidth = allergensContainerRef.current.clientWidth;

    const children = allergensContainerRef.current.children;

    let allergensTotalWidth = 0;

    let index = 0;

    let row = 1;

    while (index < children.length && row < 3) {
      if (row === 1) {
        if (
          elementWillOverflow(
            containerWidth * row,
            children[index] as HTMLElement,
            allergensTotalWidth
          )
        ) {
          // fill allergens width with the remaining empty space of container and go to next row
          allergensTotalWidth += containerWidth - allergensTotalWidth;

          row++;
        } else {
          if (!itemOnNextRow(children[index] as HTMLElement, row)) {
            // we can still add to first row
            allergensTotalWidth += children[index].scrollWidth;
            index++;
            continue;
          } else {
            // fill allergens width with the remaining empty space of container and go to next row
            allergensTotalWidth += containerWidth - allergensTotalWidth;
            row++;
          }
        }
      }

      if (row === 2) {
        if (
          elementWillOverflow(
            containerWidth * row,
            children[index] as HTMLElement,
            allergensTotalWidth
          )
        ) {
          row++;
        } else {
          if (!itemOnNextRow(children[index] as HTMLElement, row)) {
            allergensTotalWidth += children[index].scrollWidth;
            index++;
          } else {
            row++;
          }
        }
      }
    }

    if (row === 1) {
      setNumberOfIconsDisplay(children.length);
    } else {
      // incase we've gone up to row 3 in the while loop, we dont care, we need 2 rows for the math
      row = 2;
      const selectedChildren = Array.from(children).slice(0, index);

      // calculate how much remaining space we have left so we can see if we can fit "Show more"
      const difference = containerWidth * row - allergensTotalWidth;

      // multiply show mores width by 1.2 to give a bit leeway when theres few pixels making it go down
      // if the difference is less than show mores width
      if (difference < showMoreRef.current.scrollWidth * 1.2) {
        // remove allergens until we manage to fit show more

        let newDifference = difference;
        while (newDifference < showMoreRef.current.scrollWidth * 1.2) {
          allergensTotalWidth -= selectedChildren[index - 1].scrollWidth;

          index--;
          newDifference = containerWidth * row - allergensTotalWidth;
        }
      }

      setNumberOfIconsDisplay(index);
    }
  }, [numberOfIconsDisplay]);

  let firstIcons: string[] = [];
  let restIcons: string[] = [];

  if (combinedIcons.length <= numberOfIconsDisplay) {
    firstIcons = combinedIcons.slice(0, numberOfIconsDisplay);
  }

  if (combinedIcons.length > numberOfIconsDisplay) {
    firstIcons = combinedIcons.slice(0, numberOfIconsDisplay);
    restIcons = combinedIcons.slice(numberOfIconsDisplay, combinedIcons.length);
  }

  if (!priceDisplay) {
    return null;
  }

  return (
    <ContentContainer data-testid="menu-item-container">
      <StyledItemPriceAndCalloriesContainer>
        {!props.item.isSuspended ? (
          <MenuItemPrice data-testid="menu-item-price">
            {FormatPrice(priceDisplay)}{' '}
          </MenuItemPrice>
        ) : (
          <BadgeUnavailable label={t('restaurant.menu.suspended')} />
        )}

        {props.item.calories > 0 && (
          <InfoDivider>{!props.item.isSuspended && '•'}</InfoDivider>
        )}

        {props.item.calories > 0 && (
          <MenuItemCalories data-testid="menu-item-calories">
            {props.item.calories} {t('restaurant.menu.cal')}
          </MenuItemCalories>
        )}
      </StyledItemPriceAndCalloriesContainer>
      <MenuItemDescription description={props.item.description} />
      <SubtitleContainer>
        <AllergenIconsContainer ref={allergensContainerRef}>
          {firstIcons.map((icon, idx) => {
            return (
              <StyledAllergenPill key={`allergen-${icon}-${idx}`}>
                <AllergenIcon
                  AllergenSVG={mapAllergenStringToSVG(icon)}
                  marginRight={4}
                />{' '}
                {t(`restaurant.allergens.${icon.toLowerCase()}`)}
              </StyledAllergenPill>
            );
          })}

          {combinedIcons.length > numberOfIconsDisplay &&
            showMore &&
            restIcons.map((icon, idx) => {
              return (
                <StyledAllergenPill key={`allergen-${icon}-${idx}`}>
                  <AllergenIcon
                    AllergenSVG={mapAllergenStringToSVG(icon)}
                    marginRight={4}
                  />{' '}
                  {t(`restaurant.allergens.${icon.toLowerCase()}`)}
                </StyledAllergenPill>
              );
            })}

          <StyledToggleButton
            ref={showMoreRef}
            disableRipple={true}
            onClick={onToggleShowMore}
            style={{
              display:
                numberOfIconsDisplay < combinedIcons.length
                  ? 'inline-flex'
                  : 'none'
            }}
          >
            {showMore
              ? t('restaurant.menu.showLess')
              : t('restaurant.menu.showMore')}
          </StyledToggleButton>
        </AllergenIconsContainer>
      </SubtitleContainer>
    </ContentContainer>
  );
};

export default MenuItemDetails;
