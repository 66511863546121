import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import { useMenu } from '../../../../hooks/useMenu';
import { useEmptyCartRedirect } from '../../../../hooks/useEmptyCartRedirect';
import { useCartV2 } from '../../../../hooks/useCartV2';
import AddTip from '../AddTip';
import RoundUp from '../RoundUp/RoundUp';
import OrderTotal from '../../../../components/OrderTotal/OrderTotal';
import OrderSummary from '../../../../components/OrderSummary';
import GuestInformation from '../GuestInformation';
import PickupInfo from '../PickupInfo/PickupInfo';
import ContinueToPayment from '../ContinueToPayment/ContinueToPayment';
import PickupTimeAlert from '../../../../components/PickupTimeAlert/PickupTimeAlert';
import { styled } from '@mui/material/styles';
import { StoreInterface } from '../../../../types/stores';
import { UserInfo } from '../../../../types/userInfo';
import { SiteConfig, PaymentGateway } from '../../../../types/fetch';
import validationSchema from '../../Functions/userInfoValidation';
import ErrorDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog';
import Button, { ButtonProps } from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import PaymentForm from '../../../PaymentForm/PaymentForm';
import { GatewayError } from '../../../Gateways/GatewayError';
import ProcessingOrderDialog from '../ProcessingOrderDialog/ProcessingOrderDialog';
import {
  createSearchParams,
  generatePath,
  useNavigate
} from 'react-router-dom';
import AppRoute from '../../../../routing/AppRoute';
import { resetSession } from '../../../../utils/Session/Session';
import { usePickupTimes } from '../../../../hooks/usePickupTimes';
import logger, { LoggerEvent } from '../../../../services/logger';
import { useCalculate } from '../../../../hooks/useCalculate';
import toast from 'react-hot-toast';
import { PricingOptions } from '../../../Cart/types';
import { OrderType } from '../../../../types/order';
import { PaymentMethodType } from '../../../Gateways/types/GatewayResponse';
import formatDisplayTime from '../../../../utils/Menu/formatDisplayTime';
import Banner from '../../../../components/Banner/Banner';
import isClosingBannerVisible from '../../../../utils/Menu/isClosingBannerVisible';

interface CheckoutContentProps {
  store: StoreInterface;
  siteConfig: SiteConfig;
  paymentGateway: PaymentGateway;
  onOpenPaymentDrawer: (isOpen: boolean) => void;
}

const StyledCheckoutContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(30)
}));

const BannerContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(13)
}));

const StyledCheckoutTitle = styled('h5')(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.spacing(5),
  marginTop: theme.spacing(17),
  marginBottom: theme.spacing(8),
  alignSelf: 'center'
}));

const StyledCheckoutSectionDivider = styled('div')(({ theme }) => ({
  borderTop: '1px solid',
  borderColor: theme.colors.gray[300],
  marginBottom: theme.spacing(4)
}));

const StyledConfirmationButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.colors.partner[500]
}));

const StyledPaymentDrawer = styled(Drawer)({
  zIndex: 1300,
  '.MuiDrawer-paper': {
    minHeight: 'calc(100vh)',
    paddingTop: '0',
    overflowY: 'auto',
    width: '100%',
    maxWidth: '1024px',
    margin: '0 auto',
    position: 'relative',
    display: 'block'
  }
});

const CheckoutContent = ({
  store,
  siteConfig,
  paymentGateway,
  onOpenPaymentDrawer
}: CheckoutContentProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logEvent = useAnalytics();
  const showTip = siteConfig.hasTip;
  const showRoundUp = siteConfig.hasRoundUp;
  const [processingOrder, setProcessingOrder] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>({
    name: '',
    phone: '',
    table: '',
    optIn: true
  });
  const [orderTimeStamp] = useState(new Date().toISOString());
  const [useDialog, setUseDialog] = useState(false);

  const [errorText, setErrorText] = useState<{
    title: string;
    description: string;
  }>({
    title: '',
    description: ''
  });

  const { tip, hasRoundUp, promoCode, clearCart, pickupTime, priceToDisplay } =
    useCartV2();
  const { data: menu } = useMenu(store ? store.id : '');
  const { refetch } = usePickupTimes(store?.id ?? '');
  const { cartCalculations, errorDialog, getTotals, dialogOpen } =
    useCalculate(store);

  const [bannerVisible, setBannerVisible] = useState(false);
  const [storeClosingTimeToday, setStoreClosingTime] = useState('');
  const userTime = new Date();
  useEmptyCartRedirect();

  useEffect(() => {
    if (menu?.closingTime) {
      setStoreClosingTime(menu.closingTime);

      const interval = setInterval(() => {
        const visibility = isClosingBannerVisible(
          userTime,
          menu.closingTime,
          siteConfig.timeZone
        );
        setBannerVisible(visibility);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [menu]);

  useEffect(() => {
    logEvent('begin_checkout');
    // eslint-disable-next-line
  }, []);

  const [openPaymentsDrawer, setOpenPaymentsDrawer] = useState<boolean>(false);

  const onPaymentDrawerInteraction = (isOpen: boolean) => {
    setOpenPaymentsDrawer(isOpen);
    onOpenPaymentDrawer(isOpen);
  };

  useEffect(() => {
    // onload sets the margin for the toasts to start above the submit bar in CardPointe form
    // fixes invisible toasts covering the place order button and starts toasts in correct vertical spot
    const toasterContainer = document.getElementsByClassName(
      'customToasterClass'
    )[0] as HTMLElement;
    if (toasterContainer) {
      toasterContainer.style.marginBottom = openPaymentsDrawer ? '70px' : '';
      toasterContainer.style.clipPath = openPaymentsDrawer
        ? 'inset(0% 0% -6px 0%)'
        : '';
    }
    if (!openPaymentsDrawer) {
      // Dismiss all toasts on close of drawer
      toast.dismiss();
    }
  }, [openPaymentsDrawer]);

  // start error dialog logic
  const [openErrorDialog, setOpenErrorDialog] = useState<boolean>(false);

  const handleCloseError = () => {
    setErrorText({ title: '', description: '' });
    setOpenErrorDialog(false);
  };
  // end error dialog logic

  // close payment drawer on back navigation
  window.onpopstate = () => {
    onPaymentDrawerInteraction(false);
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data: FieldValues) => {
    const guestInfo = {
      name: data.name,
      phone: data.phone,
      table: data.deliveryLocation,
      optIn: data.optIn
    };
    setUserInfo(guestInfo);

    logEvent('round_up_selection', {
      roundUpSelected: hasRoundUp
    });

    onPaymentDrawerInteraction(true);
  };

  const paymentOnSubmit = () => {
    setUseDialog(true);
    setProcessingOrder(true);
    return;
  };

  const paymentOnError = async (error: GatewayError) => {
    setProcessingOrder(false);

    if (error.message?.toLowerCase() === 'incomplete') {
      return;
    }

    onPaymentDrawerInteraction(false);

    if (
      error.message?.toLowerCase() === 'invalid_adv_order' ||
      error.message?.toLowerCase() === 'no_capacity' ||
      error.errorCode?.toLowerCase() === 'invalid_adv_order' ||
      error.errorCode?.toLowerCase() === 'no_capacity'
    ) {
      await refetch();
      return;
    }

    if (
      error.message?.toLowerCase() === 'bad_cart_item_suspended' ||
      error.errorCode?.toLowerCase() === 'bad_cart_item_suspended'
    ) {
      getTotals();
      return;
    }

    logger.error(LoggerEvent.PROCESS_ERROR, 'Process Error', error);

    if (error.message?.toLowerCase() === 'unknown') {
      logEvent('checkout_unexpected_error');
    } else {
      const logEventPayload = {
        error_message: error.message,
        payment_method: error.paymentMethod,
        notification_type: 'SMS',
        notification_status: userInfo.optIn ? 'opt_in' : 'opt_out'
      };
      logEvent('purchase_failure', logEventPayload);
    }

    setErrorText({
      title: t(error.title ?? 'error.processing.title'),
      description: t(error.description ?? 'error.processing.description')
    });

    setOpenErrorDialog(true);
    return;
  };

  const paymentOnSuccess = (
    publicOrderIdResponse: string,
    paymentMethod: PaymentMethodType
  ) => {
    clearCart();
    resetSession();
    logEvent('purchase_success', {
      payment_method: paymentMethod,
      notification_type: 'SMS',
      notification_status: userInfo.optIn ? 'opt_in' : 'opt_out'
    });
    logEvent('end_checkout', {
      eat_transactionId: publicOrderIdResponse.toString(),
      roundup_selected: hasRoundUp,
      tip_selected: tip.type !== 'CUSTOM' ? tip.type : 0,
      custom_tip: tip.type === 'CUSTOM',
      pickup_type: pickupTime ? 'Advanced_Order' : 'ASAP',
      time_ahead: pickupTime
        ? new Date(pickupTime).getTime() - new Date().getTime() * 60
        : 0
    });
    // setPublicOrderId(publicOrderIdResponse);
    navigate(
      generatePath(AppRoute.ORDER_STATUS, {
        storeId: String(store.shortId)
      }) +
      '?' +
      createSearchParams({ g: String(publicOrderIdResponse) }),
      { replace: true }
    );
  };

  const sessionTimeOut = async () => {
    // Freedom Pay session timeout error
    setProcessingOrder(false);
    onPaymentDrawerInteraction(false);
    setErrorText({
      title: t('error.sessionTimeout.title'),
      description: t('error.sessionTimeout.description')
    });

    setOpenErrorDialog(true);
    await refetch();
    return;
  };

  if (
    !dialogOpen &&
    (!cartCalculations ||
      !cartCalculations.data ||
      !cartCalculations.data.summary ||
      !cartCalculations.data.summary.total ||
      Number(cartCalculations.data.summary.total) <= 0)
  ) {
    return null;
  }

  const taxesAndFeesExpanded =
    siteConfig.warningFlags.indexOf('ServiceFeeAlert') > -1 &&
    cartCalculations.data.summary.fee > 0;

  return (
    <>
      <BannerContainer>
        <Banner
          bannerText={`${t(
            'restaurant.menu.currentMenuEnds'
          )} ${formatDisplayTime(storeClosingTimeToday)}.`}
          hasRoundedCorners={false}
          isVisible={bannerVisible}
        ></Banner>
      </BannerContainer>
      <StyledCheckoutContainer data-testid="checkout-container">
        <StyledCheckoutTitle data-testid="checkout-title">
          {t('restaurant.main.checkout')}
        </StyledCheckoutTitle>
        {store.mobileDeliveryManagement !== 'table' && (
          <>
            <PickupInfo
              restaurantName={store.name}
              isAdvancedOrdering={store.hasAdvancedOrdering}
              priceToDisplay={priceToDisplay}
            />
            <StyledCheckoutSectionDivider />
          </>
        )}
        <FormProvider {...methods}>
          <form
            data-testid="customer-info-form"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <GuestInformation
              mobileDeliveryManagement={store.mobileDeliveryManagement}
            />
          </form>
        </FormProvider>
        {showTip && (
          <AddTip
            tipAmount={cartCalculations?.data.summary.tip || 0}
            orderTotal={cartCalculations.data.summary.total}
            tipPreview={cartCalculations.data.summary.tipPreview}
            cartCalculations={cartCalculations}
          />
        )}
        {showRoundUp && (
          <RoundUp
            previewRoundUp={cartCalculations?.data.summary?.previewRoundUp || 0}
          />
        )}
        <OrderSummary
          title={t('restaurant.checkout.yourOrder')}
          removable={false}
          cartCalculations={cartCalculations}
        />
        <OrderTotal
          showTip={showTip}
          showRoundUp={hasRoundUp}
          cartCalculations={cartCalculations.data.summary}
          promoCode={promoCode}
          tipType={
            tip.type?.toLowerCase() === 'dollar'
              ? 'Custom'
              : `${tip.value?.toString()}%`
          }
          orderType={
            priceToDisplay === PricingOptions.TAKEOUT
              ? OrderType.takeOut
              : OrderType.dineIn
          }
          expandTaxesAndFees={taxesAndFeesExpanded}
        />
        <PickupTimeAlert showDialog={useDialog} />
        <ErrorDialog
          open={openErrorDialog}
          handleClose={handleCloseError}
          title={errorText.title}
          content={errorText.description}
        >
          <StyledConfirmationButton
            onClick={handleCloseError}
            data-testid="checkout-error-dialog-button"
          >
            {t('restaurant.main.ok')}
          </StyledConfirmationButton>
        </ErrorDialog>

        <StyledPaymentDrawer
          anchor="bottom"
          open={openPaymentsDrawer}
          onClose={() => onPaymentDrawerInteraction(false)}
          hideBackdrop={false}
        >
          <PaymentForm
            userInfo={userInfo}
            cartCalculations={cartCalculations}
            storeId={store.id}
            siteId={siteConfig.partnerId}
            storeName={store.name}
            menuId={menu?.menuId}
            onSubmit={paymentOnSubmit}
            onSuccess={paymentOnSuccess}
            onError={paymentOnError}
            handleClose={() => onPaymentDrawerInteraction(false)}
            paymentGateway={paymentGateway}
            orderTimeStamp={orderTimeStamp}
            sessionTimeout={sessionTimeOut}
          />
        </StyledPaymentDrawer>
        {(processingOrder || status === 'processing') && (
          <ProcessingOrderDialog />
        )}
      </StyledCheckoutContainer>
      <ContinueToPayment handlePress={methods.handleSubmit(onSubmit)} />
      {errorDialog}
    </>
  );
};

export default CheckoutContent;
