import { useEffect, useState } from 'react';
import {
  CartCalculation,
  CartItem,
  PricingOptions
} from '../features/Cart/types';
import getCalculations from '../utils/API/getCalculations';
import { useCartV2 } from './useCartV2';
import { useMenu } from './useMenu';
import { StoreInterface } from '../types/stores';
import ItemNoLongerAvailableDialog from '../components/ItemNoLongerAvailableDialog/ItemNoLongerAvailableDialog';
import { useAnalytics } from './useAnalytics';
import { OrderType } from '../types/order';

export const useCalculate = (store: StoreInterface) => {
  const logEvent = useAnalytics();
  const {
    items: cartItems,
    promoCode,
    tip,
    hasRoundUp,
    priceToDisplay
  } = useCartV2();
  const { data, isLoading } = useMenu(store ? store.id : '');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [isTotalsLoading, setIsTotalsLoading] = useState(false);
  const [itemsRemoved, setItemsRemoved] = useState<CartItem[]>([]);
  const [cartCalculations, setCartCalculations] = useState<{
    data: CartCalculation;
  }>({
    data: {
      cart: [],
      summary: {
        discount: 0,
        fee: 0,
        additionalFees: [],
        previewRoundUp: 0,
        subtotal: 0,
        tax: 0,
        taxLabel: 'tax',
        tip: 0,
        tipPercentage: 0,
        tipPreview: [],
        total: 0
      }
    }
  });

  const getTotals = () => {
    setIsTotalsLoading(true);
    getCalculations(
      store.id,
      cartItems,
      priceToDisplay === PricingOptions.TAKEOUT
        ? OrderType.takeOut
        : OrderType.dineIn,
      data?.menuId,
      promoCode,
      tip,
      hasRoundUp
    )
      .then((calculations) => {
        if (!calculations) {
          return;
        }
        setIsTotalsLoading(false);
        setCartCalculations(calculations);
      })
      .catch(async (e) => {
        if (
          !e.response ||
          !e.response.status ||
          e.response.status !== 422 ||
          e.response.data?.errorCode.toUpperCase() !== 'BAD_CART_ITEM_SUSPENDED'
        ) {
          return;
        }

        logEvent('product_suspended');
        const unavailableItems: number[] = e.response.data.errors.map(
          (item: { itemId: number }) => {
            return item.itemId;
          }
        );

        const itemsToRemove = cartItems.filter((item) =>
          unavailableItems.includes(item.productId)
        );

        setItemsRemoved(
          itemsToRemove.map((item) => {
            return item;
          })
        );
        setIsTotalsLoading(false);
        setDialogOpen(true);
      });
  };

  const onClose = () => {
    setItemsRemoved([]);
    setDialogOpen(false);
  };

  useEffect(() => {
    if (isLoading) {
      return;
    }

    getTotals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.id,
    cartItems,
    data?.menuId,
    promoCode,
    tip,
    hasRoundUp,
    isLoading
  ]);

  return {
    getTotals,
    isTotalsLoading,
    cartCalculations: cartCalculations,
    dialogOpen,
    errorDialog: (
      <ItemNoLongerAvailableDialog
        open={dialogOpen}
        close={onClose}
        itemsRemoved={itemsRemoved}
        store={store}
      />
    )
  };
};
