import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import AllergenIcon from '../../../../components/AllergenIcon';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAnalytics } from '../../../../hooks/useAnalytics';
import AppRoute from '../../../../routing/AppRoute';
import { MenuItem } from '../../types';
import MenuItemFlag from '../MenuItemFlag/MenuItemFlag';
import { useTranslation } from 'react-i18next';
import FormatPrice from '../FormatPrice/FormatPrice';
import { SessionKeys } from '../../../../utils/Session/Session';
import { PricingOptions } from '../../../Cart/types';
import BadgeUnavailable from '../../../../components/Badges/BadgeUnavailable';
import AllergensDisplay from '../../../../components/AllergensDisplay';
import mapAllergenStringToSVG from '../../../../utils/API/mapAllergenStringToSVG';

export interface MenuItemCardProps {
  menuItem: MenuItem;
  storeId: string;
  priceToDisplay?: PricingOptions;
}

const StyledMenuItemContainer = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(7),
  color: theme.colors.base.black,
  borderRadius: theme.spacing(1.5)
}));

const LeftContentContainer = styled('div')(({ theme }) => ({
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  height: '100%'
}));

const MenuItemTitle = styled('h3')(({ theme }) => ({
  fontSize: theme.spacing(4),
  weight: 600,
  margin: 0,
  paddingBottom: theme.spacing(1)
}));

const StyledMenuItemDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(3),
  fontWeight: 400,
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3,
  textOverflow: 'ellipsis',
  marginBottom: theme.spacing(4),
  flexGrow: 1
}));

const StyledMenuItemIconCalContainer = styled(Box)(({}) => ({
  display: 'flex',
  alignItems: 'center'
}));

const StyledCaloriesContainer = styled('div')(({ theme }) => ({
  fontSize: theme.spacing(3),
  marginRight: theme.spacing(3)
}));

const StyledMenuItemMediaContainer = styled(Box, {
  shouldForwardProp: (prop: string) =>
    prop !== 'menuItemImageUrl' && prop !== 'isSuspended'
})<{ menuItemImageUrl: string; alt: string; isSuspended: boolean }>(
  ({ theme, menuItemImageUrl, isSuspended }) => ({
    width: theme.spacing(33.5),
    minHeight: theme.spacing(33.5),
    height: '100%',
    backgroundImage: `url(${menuItemImageUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column-reverse',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.colors.base.black,
    marginLeft: 'auto',
    position: 'relative',
    '&:after': isSuspended
      ? {
          content: '" "',
          position: 'absolute',
          zIndex: 101,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: theme.colors.base.black,
          opacity: isSuspended ? '0.5' : '1'
        }
      : ''
  })
);

const StyledMenuItemPriceContainer = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isSuspended'
})<{ isSuspended: boolean }>(() => ({
  height: 24,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  zIndex: '102',
  position: 'absolute',
  top: 'calc(100% - 39px)',
  right: '6px'
}));

const StyledSuspendedMenuItemPriceContainer = styled(Box)(() => ({
  height: 24,
  display: 'flex',
  flex: 'auto',
  justifyContent: 'end',
  alignItems: 'center'
}));

const StyledSuspendedMenuItemPrice = styled('h4')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: theme.spacing(3),
  fontSize: theme.spacing(3.5),
  lineHeight: 0,
  fontWeight: 600,
  textAlign: 'center',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const MenuItemPrice = styled('h4')(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  height: 24,
  borderRadius: theme.spacing(3),
  fontSize: theme.spacing(3.5),
  lineHeight: theme.spacing(6),
  fontWeight: 600,
  textAlign: 'center',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2)
}));

const MenuItemCard = ({
  menuItem,
  storeId,
  priceToDisplay
}: MenuItemCardProps) => {
  const {
    name,
    description,
    allergens,
    dietaryOptions,
    dineInPrice,
    takeOutPrice,
    imageUrl,
    productId,
    attributes,
    isSuspended,
    calories
  } = menuItem;
  const navigate = useNavigate();
  const logEvent = useAnalytics();
  const { t } = useTranslation();

  const priceDisplay =
    priceToDisplay === PricingOptions.TAKEOUT ? takeOutPrice : dineInPrice;

  if (!priceDisplay) {
    return null;
  }

  const lowResImageUrl = imageUrl + '?fit=fill&w=134&h=134';

  const handleClick = () => {
    if (storeId) {
      const analyticPayload = {
        item_id: menuItem.productId,
        category_name: menuItem.category
      };

      logEvent('view_item', analyticPayload);

      sessionStorage.setItem(
        SessionKeys.SCROLL_POSITION,
        String(window.scrollY)
      );

      navigate(
        generatePath(AppRoute.MENU_ITEM, {
          storeId: String(storeId),
          itemId: String(menuItem.productId)
        })
      );
    } else navigate(AppRoute.HOME);
  };

  const leftContainerSize = imageUrl && imageUrl !== '' ? 7.1 : true;

  return (
    <div style={{ position: 'relative' }}>
      <MenuItemFlag
        style={{ position: 'absolute', top: '-0.75rem', left: '1rem' }}
        flags={attributes}
      />
      <StyledMenuItemContainer data-testid={`menu-item-card-${productId}`}>
        <CardActionArea onClick={handleClick}>
          <Grid container>
            <Grid item xs={leftContainerSize}>
              <LeftContentContainer>
                <MenuItemTitle
                  data-testid={`menu-item-title-${productId}`}
                  aria-label={`${name} ${FormatPrice(priceDisplay)}`}
                >
                  {name}
                </MenuItemTitle>
                <StyledMenuItemDescription
                  data-testid={`menu-item-description-${productId}`}
                >
                  {description}
                </StyledMenuItemDescription>
                <StyledMenuItemIconCalContainer>
                  {calories > 0 && (
                    <StyledCaloriesContainer
                      data-testid={`menu-item-calorie-${productId}`}
                    >
                      {calories} {t('restaurant.menu.cal')}
                    </StyledCaloriesContainer>
                  )}
                  <AllergensDisplay limit={2}>
                    {allergens.map((icon, idx) => {
                      const Icon = mapAllergenStringToSVG(icon);
                      return (
                        <AllergenIcon
                          key={idx}
                          AllergenSVG={Icon}
                          marginRight={-1.6}
                          testIdExtension={`${idx}`}
                        />
                      );
                    })}
                    {dietaryOptions.map((icon, idx) => {
                      const Icon = mapAllergenStringToSVG(icon);
                      return (
                        <AllergenIcon
                          key={idx}
                          AllergenSVG={Icon}
                          marginRight={-1.6}
                          testIdExtension={`${idx}`}
                        />
                      );
                    })}
                  </AllergensDisplay>
                  {
                    // prettier-ignore
                    !imageUrl ||
                      imageUrl === '' ? (
                      <StyledSuspendedMenuItemPriceContainer>
                        {isSuspended ? (
                          <BadgeUnavailable
                            label={t('restaurant.menu.suspended')}
                          />
                        ) : (
                          <StyledSuspendedMenuItemPrice
                            data-testid={`menu-item-price-${productId}`}
                            aria-hidden="true"
                          >
                            {FormatPrice(priceDisplay)}
                          </StyledSuspendedMenuItemPrice>
                        )}
                      </StyledSuspendedMenuItemPriceContainer>
                    ) : null
                  }
                </StyledMenuItemIconCalContainer>
              </LeftContentContainer>
            </Grid>
            {imageUrl !== null && imageUrl !== '' && (
              <Grid item xs={4.9}>
                <StyledMenuItemMediaContainer
                  menuItemImageUrl={lowResImageUrl}
                  alt="A picture of the food item"
                  data-testid={`menu-item-image-${productId}`}
                  isSuspended={isSuspended}
                >
                  <StyledMenuItemPriceContainer isSuspended={isSuspended}>
                    {isSuspended ? (
                      <BadgeUnavailable
                        label={t('restaurant.menu.suspended')}
                      />
                    ) : (
                      <MenuItemPrice
                        data-testid={`menu-item-price-${productId}`}
                        aria-hidden="true"
                      >
                        {FormatPrice(priceDisplay)}
                      </MenuItemPrice>
                    )}
                  </StyledMenuItemPriceContainer>
                </StyledMenuItemMediaContainer>
              </Grid>
            )}
          </Grid>
        </CardActionArea>
      </StyledMenuItemContainer>
    </div>
  );
};

export default MenuItemCard;
